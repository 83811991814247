@use 'variables';

.servicosSection {
    background: rgb(2,0,36);
    background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(10,21,61,1) 46%);
    width: 100%;
    padding: 160px 0px;

    &__title{
        text-align: center;
        font-weight: 500;
        font-size: 32px;
        padding-top: 32px;
        margin-bottom: 24px;
        
    }

    &__content {

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 60px;
        cursor: pointer;


    }

    &__card {
        width: 360px;
        height: 340px;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(232, 232, 241, 0.322) 0%, rgba(240, 243, 253, 0.13) 46%);
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 8px;
        

        h2 {
            font-weight:500;
            font-size: 24px;
            
        }

        p {
            text-align: center;
            padding: 16px;
            font-size: 22px;
            max-width: 100%;
            min-height: 50px;
            max-height: 120px; 
            
            
        }
    }
    
    @media screen and (max-width: 750px){

        &__card{
        width: 240px;
        height: 220px;
        h2 {
            font-weight:500;
            font-size: 16px;
            
        }

        p {
            
            text-align: center;
            padding: 8px;
            font-size: 12px;
            max-width: 100%;
            min-height: 10px;
            max-height: 120px; 
            
        }
        

        }
        .slick-next:before, .slick-prev:before{
        color: #9494948e;
        font-size: 24px;

        }
    
    }
}


.slick-slide{ 
    margin: 16px;
}



