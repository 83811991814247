@use 'variables' as *;


@mixin displayDasDivs() {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    font-size: 24px;
    margin: 8px;
}

.header{
    background-color: $headerBackground;
    font-family: 'Montserrat', sans-serif;
    list-style: none;
    padding: 10px 85px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: -9px -7px 8px 12px #000000;
    position: fixed;
    z-index: 1000;
    width: 100%;
    box-sizing: border-box; 
    
    
    &__title{
        img{
            width: 136px;
            align-content: center;
            margin-top: 10px
        }
    }

    ul,li {
        @include displayDasDivs();
        
        
    }

    &__item{
        transition: transform 0.2s ease, letter-spacing 0.2s ease-out;
        padding: 8px;
        will-change: transform, letter-spacing;
        &:hover{
            letter-spacing: 0.2em;
            
        }
    }



    &__icons li a {
        font-size: 24px;
        transition: transform 0.3s ease;
        &:hover{
            transform: scale(1.09);
        }

    
    }

    .hamburguer{

        display: none;
        border: none;
        background: none;
        border-top: 3px solid #fff;
        cursor: pointer;

        &:before,
        &:after{
            content: " ";
            display: block;
            width: 30px;
            height: 3px;
            background: #fff;
            margin-top: 5px;
            transition: transform 0.3s ease, opacity 0.3s ease;
        }
        

        &--active {
        border-top-color: transparent;

        &:before {
            transform: rotate(45deg) translate(3px, 3px);
        }

        &:after {
            transform: rotate(-45deg) translate(3px, -3px);
        }
    }
    }

    @media screen and (max-width: 762px) {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 24px;
        
        &__item{
        transition: transform 0.1s ease-in, letter-spacing 0.1s ease-in;
        padding: 16px;
        will-change: transform, letter-spacing;
        &:hover{
            letter-spacing: 0.1em;
            
        }
    }


        &__nav{
        display: flex;
        justify-content: space-around;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: $headerBackground;
        clip-path: circle(100px at 90% -15%);
        transition: 1s ease-out;
        pointer-events: none;
            
            &--active{
                position: fixed;
                top: 16px;
                right: 16px;                
                clip-path: circle(1500px at 90% -15%);
                pointer-events: all;
                border-top-color: transparent;
            }
            
            ul{ 
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                gap: 0;

            }
        }

    


        &__icons{
        display: none;
        }

        &__item a{
            font-size: 32px;
            text-align: center;
        }

        .hamburguer{
        
            display: block;
            z-index: 1;
            transition: 0.3s;
            position: relative;
            position: fixed;
            
            
        }


    }

    @media screen and (max-width: 375px){
        &__title{
        img{
            width: 100px;
            align-content: center;
            margin-top: 10px
        }
    }

    &__item a{
            font-size: 24px;
            text-align: center;
        }
    }

}
