@use 'variables' as *;

.mainSection {
    background-image: url('../images/fundo.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100vh;
        background-color: rgba(14, 21, 48, 0.801); 
        backdrop-filter: blur(4px); 
}


    &__content{
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        width: 100%;
        z-index: 1;
        padding-top: 180px;
        h2{
            font-size: 64px;
            font-weight: 600;
        }
        h3{
            margin-top: 8px;
            font-size: 24px;
            font-weight:200;
        }
        h4{
            margin-top: 8px;
            font-size: 16px;
            font-weight:200;
        }
    }
    &__primario{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        .logo{
            img{
                max-width: 340px;
            }
        }
    }
    &__secundario{
        
            p, .p_mobile{
                font-size: 24px;
                text-align: center;
                
            }
        }


    @media screen and (max-width: 750px){
        background-position: center;
        
        &__content{
            padding-top: 180px;
        }
        .mainSection__primario {
            .logo{
                img{
                    max-width: 180px;
                }
            }
        }
        .mainSection__secundario{
            margin-top: 16px;
            p, .p_mobile {
                font-size: 1rem;
            }
        }
        .container {
        max-width: 90%;
        }
        &__content {
        display: block;
        }
        
    }
    @media screen and (max-width: 375px){
        
        &__content{
            h3{
                font-size: 16px;
            }
        }
        .mainSection__primario {
            .logo{
                img{
                    max-width: 140px;
                }
            }
        }
        .mainSection__secundario{
            margin-top: 40px;
            p, .p_mobile {
                font-size: 1rem;
            }
            
        }
    }   
}