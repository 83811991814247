@use 'variables';

footer {
    background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(10,21,61,1) 46%);
    width: 100%;
    padding: 80px 0;
    
}

p{
    text-align: center;
    padding-top: 8px;
}

.rodape {

    .copy{
        text-align: center;
        padding-top: 80px;
    }

    &__content{
        display: flex;
        justify-content: space-between;
        justify-items: center;
    }

    &__contato{
        .whatsapp {
            display: flex;
            align-items: center;
            gap: 24px;
        }

        .email {
            margin-top: 24px;
            gap: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .telefoneFixo{
            margin-top: 24px;
            display: flex;
            align-items: center;
            gap: 24px;
        }
    }

    @media screen and (max-width: 767px){
        &__content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        justify-items: center;
        text-align: center;
    }

    
    
    h2{
        margin-top: 16px;
        font-size: 24px;
    }

    p{  
        font-size: 16px;
        margin: 8px;
    }

    &__contato{
    
        .whatsapp {
            display: block;
            align-items: center;
            
        }

        .email {
            
            display: block;
            align-items: center;
            
        }

        .telefoneFixo{
            display: block;
            align-items: center;
            
        }
    }
    }
    
}




.bi {
    font-size: 24px;
    width: 10%;
    @media screen and (max-width: 767px){
        font-size: 16px;
    }
}



.map-desktop {
    margin-top: 16px;
    border-radius: 8px;
    @media screen and (max-width: 767px){
        display: none;
    }
}

.map-mobile{
    @media screen and (min-width: 767px){
        display: none;
        margin-top: 16px;
        border-radius: 8px;
        margin: 16px;
    }
}

h2 {
    font-weight: 500;
    font-size: 32px;
    margin-bottom: 16px;
}