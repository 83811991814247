@use 'variables' as *;

.aboutSectionThree{
    background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(10,21,61,1) 46%);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    
    img{
            max-width: 80%;
            border-radius: 8px;
            max-height: 512px;
        }
    .carouselContent{
        display: flex;
        justify-content: center;
    }

    .slick-slide

    &__information{

            h2{
                font-size: 32px;
                margin-bottom: 16px;
                font-weight: 600;
            }
            h3{
                font-size: 24px;
                font-weight: 200;
                font-style: italic;
                margin-bottom: 16px;
            }
            p{
                font-size: 24px;
            }
        }

        @media screen and (max-width: 762px) {
            padding-top: 24px;
            .slick-next:before, .slick-prev:before{
            color: #9494948e;
            font-size: 16px;
            }

        
            img{
                max-width: 260px;
                border-radius: 8px;
                
            }

            .container{
                max-width: 70%;
            }
            &__information{

                
                h2{
                    font-size: 24px;
                    margin-bottom: 16px;
                    font-weight: 600;
                }
                h3{
                    font-size: 16px;
                    font-weight: 200;
                    font-style: italic;
                    margin-bottom: 16px;
                }
                p{
                    font-size: 16px;
                }
                .carouselContent, .slick-slide{
                    width: 280px;
                    margin: 16px;
                }
            }
        }

}


