
@use 'variables' as *;
@use 'header';
@use 'mainSection';
@use 'aboutSectionOne';
@use 'aboutSectionTwo';
@use 'aboutSectionThree';
@use 'servicosSection';
@use 'footer';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");



*{
    margin: 0;
    padding: 0;
    box-sizing:border-box;
    font-family: 'Montserrat', sans-serif;
    color: $textColor;
    list-style: none;
    text-decoration: none;
    scroll-behavior: smooth;
    
}


.container{
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 750px){
        max-width: 90%;
        overflow-x: hidden;
    }
}

.slick-dots li button:before{
    color: #ffff;
}

.contactBtn{
        margin-top: 40px;
        width: 220px;
        padding: 16px;
        border-radius: 20px;
        border: none;
        cursor: pointer;
        transition: transform 0.2s ease-out, box-shadow 0.1s ease-in;
        box-shadow: 3px 3px 8px 0px #676b99;
        &:hover{
            transform: translateY(-5px);
            box-shadow: 1px 3px 10px 0px #ffffff;
        }

        a{ 
        color: $textButtonColor;
        font-weight: 600;
        font-size: 16px;
        }
    
        @media screen and (max-width: 375px){
            margin-top: 16px;
            width: 180px;
            padding: 8px;
            a{ 
                color: $textButtonColor;
                font-weight: 600;
                font-size: 12px;
        }
        }
    }