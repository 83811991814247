@use 'variables' as *;

.aboutSectionOne{
    background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(10,21,61,1) 46%);
    width: 100%;
    padding: 160px 0px;
    
    
    &__content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        gap: 64px;

        
    }

    &__information{
        
            h2{
                font-size: 32px;
                margin-bottom: 16px;
                font-weight: 600;
            }
            h3{
                font-size: 24px;
                font-weight: 200;
                font-style: italic;
                margin-bottom: 16px;
            }
            p{
                font-size: 24px;
            }
        }
    img{
        width: 522px;
        border-radius: 8px;
    }

    @media screen and (max-width: 762px) {
        
        &__content{ 
            display: flex;
            flex-direction: column;
        }

        &__information{
        
            h2{
                font-size: 24px;
                margin-bottom: 16px;
                font-weight: 600;
            }
            h3{
                font-size: 16px;
                font-weight: 200;
                font-style: italic;
                margin-bottom: 16px;
            }
            p{
                font-size: 16px;
            }
        }

        img{
            margin-bottom: 24px;
            width: 280px;
        }
    }
}