@import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css";
.header {
  z-index: 1000;
  box-sizing: border-box;
  background-color: #070f2c;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 85px;
  font-family: Montserrat, sans-serif;
  list-style: none;
  display: flex;
  position: fixed;
  box-shadow: -9px -7px 8px 12px #000;
}

.header__title img {
  align-content: center;
  width: 136px;
  margin-top: 10px;
}

.header ul, .header li {
  justify-content: space-between;
  gap: 20px;
  margin: 8px;
  font-size: 24px;
  display: flex;
}

.header__item {
  will-change: transform, letter-spacing;
  padding: 8px;
  transition: transform .2s, letter-spacing .2s ease-out;
}

.header__item:hover {
  letter-spacing: .2em;
}

.header__icons li a {
  font-size: 24px;
  transition: transform .3s;
}

.header__icons li a:hover {
  transform: scale(1.09);
}

.header .hamburguer {
  cursor: pointer;
  background: none;
  border: none;
  border-top: 3px solid #fff;
  display: none;
}

.header .hamburguer:before, .header .hamburguer:after {
  content: " ";
  background: #fff;
  width: 30px;
  height: 3px;
  margin-top: 5px;
  transition: transform .3s, opacity .3s;
  display: block;
}

.header .hamburguer--active {
  border-top-color: #0000;
}

.header .hamburguer--active:before {
  transform: rotate(45deg)translate(3px, 3px);
}

.header .hamburguer--active:after {
  transform: rotate(-45deg)translate(3px, -3px);
}

@media screen and (width <= 762px) {
  .header {
    justify-content: space-around;
    align-items: center;
    gap: 24px;
    display: flex;
  }

  .header__item {
    will-change: transform, letter-spacing;
    padding: 16px;
    transition: transform .1s ease-in, letter-spacing .1s ease-in;
  }

  .header__item:hover {
    letter-spacing: .1em;
  }

  .header__nav {
    clip-path: circle(100px at 90% -15%);
    pointer-events: none;
    background-color: #070f2c;
    justify-content: space-around;
    width: 100vw;
    height: 100vh;
    transition: all 1s ease-out;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  .header__nav--active {
    clip-path: circle(1500px at 90% -15%);
    pointer-events: all;
    border-top-color: #0000;
    position: fixed;
    top: 16px;
    right: 16px;
  }

  .header__nav ul {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 0;
  }

  .header__icons {
    display: none;
  }

  .header__item a {
    text-align: center;
    font-size: 32px;
  }

  .header .hamburguer {
    z-index: 1;
    transition: all .3s;
    display: block;
    position: fixed;
  }
}

@media screen and (width <= 375px) {
  .header__title img {
    align-content: center;
    width: 100px;
    margin-top: 10px;
  }

  .header__item a {
    text-align: center;
    font-size: 24px;
  }
}

.mainSection {
  background-image: url("fundo.ec4abfed.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.mainSection:before {
  content: "";
  backdrop-filter: blur(4px);
  background-color: #0e1530cc;
  width: 100%;
  height: 100vh;
  position: absolute;
}

.mainSection__content {
  z-index: 1;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 100%;
  padding-top: 180px;
  display: grid;
  position: relative;
}

.mainSection__content h2 {
  font-size: 64px;
  font-weight: 600;
}

.mainSection__content h3 {
  margin-top: 8px;
  font-size: 24px;
  font-weight: 200;
}

.mainSection__content h4 {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 200;
}

.mainSection__primario {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.mainSection__primario .logo img {
  max-width: 340px;
}

.mainSection__secundario p, .mainSection__secundario .p_mobile {
  text-align: center;
  font-size: 24px;
}

@media screen and (width <= 750px) {
  .mainSection {
    background-position: center;
  }

  .mainSection__content {
    padding-top: 180px;
  }

  .mainSection .mainSection__primario .logo img {
    max-width: 180px;
  }

  .mainSection .mainSection__secundario {
    margin-top: 16px;
  }

  .mainSection .mainSection__secundario p, .mainSection .mainSection__secundario .p_mobile {
    font-size: 1rem;
  }

  .mainSection .container {
    max-width: 90%;
  }

  .mainSection__content {
    display: block;
  }
}

@media screen and (width <= 375px) {
  .mainSection__content h3 {
    font-size: 16px;
  }

  .mainSection .mainSection__primario .logo img {
    max-width: 140px;
  }

  .mainSection .mainSection__secundario {
    margin-top: 40px;
  }

  .mainSection .mainSection__secundario p, .mainSection .mainSection__secundario .p_mobile {
    font-size: 1rem;
  }
}

.aboutSectionOne {
  background: linear-gradient(#020024 0%, #0a153d 46%);
  width: 100%;
  padding: 160px 0;
}

.aboutSectionOne__content {
  text-align: center;
  justify-content: space-between;
  align-items: center;
  gap: 64px;
  display: flex;
}

.aboutSectionOne__information h2 {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 600;
}

.aboutSectionOne__information h3 {
  margin-bottom: 16px;
  font-size: 24px;
  font-style: italic;
  font-weight: 200;
}

.aboutSectionOne__information p {
  font-size: 24px;
}

.aboutSectionOne img {
  border-radius: 8px;
  width: 522px;
}

@media screen and (width <= 762px) {
  .aboutSectionOne__content {
    flex-direction: column;
    display: flex;
  }

  .aboutSectionOne__information h2 {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 600;
  }

  .aboutSectionOne__information h3 {
    margin-bottom: 16px;
    font-size: 16px;
    font-style: italic;
    font-weight: 200;
  }

  .aboutSectionOne__information p {
    font-size: 16px;
  }

  .aboutSectionOne img {
    width: 280px;
    margin-bottom: 24px;
  }
}

.aboutSectionTwo {
  background: linear-gradient(0deg, #020024 0%, #0a153d 46%);
  width: 100%;
  padding: 100px 0;
}

.aboutSectionTwo__content {
  text-align: center;
  justify-content: space-between;
  align-items: center;
  gap: 64px;
  display: flex;
}

.aboutSectionTwo__information h2 {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 600;
}

.aboutSectionTwo__information h3 {
  margin-bottom: 16px;
  font-size: 24px;
  font-style: italic;
  font-weight: 200;
}

.aboutSectionTwo__information p {
  font-size: 24px;
}

.aboutSectionTwo img {
  border-radius: 8px;
  width: 522px;
}

@media screen and (width <= 762px) {
  .aboutSectionTwo {
    padding-top: 24px;
  }

  .aboutSectionTwo__content {
    flex-direction: column-reverse;
    gap: 0;
    display: flex;
  }

  .aboutSectionTwo__information h2 {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 600;
  }

  .aboutSectionTwo__information h3 {
    margin-bottom: 16px;
    font-size: 16px;
    font-style: italic;
    font-weight: 200;
  }

  .aboutSectionTwo__information p {
    font-size: 16px;
  }

  .aboutSectionTwo img {
    width: 280px;
    margin-bottom: 24px;
  }
}

.aboutSectionThree {
  text-align: center;
  background: linear-gradient(#020024 0%, #0a153d 46%);
  flex-direction: column;
  justify-content: center;
  width: 100%;
  display: flex;
}

.aboutSectionThree img {
  border-radius: 8px;
  max-width: 80%;
  max-height: 512px;
}

.aboutSectionThree .carouselContent {
  justify-content: center;
  display: flex;
}

.slick-slide .aboutSectionThree__information h2 {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 600;
}

.slick-slide .aboutSectionThree__information h3 {
  margin-bottom: 16px;
  font-size: 24px;
  font-style: italic;
  font-weight: 200;
}

.slick-slide .aboutSectionThree__information p {
  font-size: 24px;
}

@media screen and (width <= 762px) {
  .aboutSectionThree {
    padding-top: 24px;
  }

  .aboutSectionThree .slick-next:before, .aboutSectionThree .slick-prev:before {
    color: #9494948e;
    font-size: 16px;
  }

  .aboutSectionThree img {
    border-radius: 8px;
    max-width: 260px;
  }

  .aboutSectionThree .container {
    max-width: 70%;
  }

  .aboutSectionThree__information h2 {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 600;
  }

  .aboutSectionThree__information h3 {
    margin-bottom: 16px;
    font-size: 16px;
    font-style: italic;
    font-weight: 200;
  }

  .aboutSectionThree__information p {
    font-size: 16px;
  }

  .aboutSectionThree__information .carouselContent, .aboutSectionThree__information .slick-slide {
    width: 280px;
    margin: 16px;
  }
}

.servicosSection {
  background: linear-gradient(0deg, #020024 0%, #0a153d 46%);
  width: 100%;
  padding: 160px 0;
}

.servicosSection__title {
  text-align: center;
  margin-bottom: 24px;
  padding-top: 32px;
  font-size: 32px;
  font-weight: 500;
}

.servicosSection__content {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 60px;
  display: flex;
}

.servicosSection__card {
  text-align: center;
  background: linear-gradient(#e8e8f152 0%, #f0f3fd21 46%);
  border-radius: 8px;
  flex-direction: column;
  width: 360px;
  height: 340px;
  padding: 8px;
  display: flex;
}

.servicosSection__card h2 {
  font-size: 24px;
  font-weight: 500;
}

.servicosSection__card p {
  text-align: center;
  max-width: 100%;
  min-height: 50px;
  max-height: 120px;
  padding: 16px;
  font-size: 22px;
}

@media screen and (width <= 750px) {
  .servicosSection__card {
    width: 240px;
    height: 220px;
  }

  .servicosSection__card h2 {
    font-size: 16px;
    font-weight: 500;
  }

  .servicosSection__card p {
    text-align: center;
    max-width: 100%;
    min-height: 10px;
    max-height: 120px;
    padding: 8px;
    font-size: 12px;
  }

  .servicosSection .slick-next:before, .servicosSection .slick-prev:before {
    color: #9494948e;
    font-size: 24px;
  }
}

.slick-slide {
  margin: 16px;
}

footer {
  background: linear-gradient(#020024 0%, #0a153d 46%);
  width: 100%;
  padding: 80px 0;
}

p {
  text-align: center;
  padding-top: 8px;
}

.rodape .copy {
  text-align: center;
  padding-top: 80px;
}

.rodape__content {
  justify-content: space-between;
  justify-items: center;
  display: flex;
}

.rodape__contato .whatsapp {
  align-items: center;
  gap: 24px;
  display: flex;
}

.rodape__contato .email {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
  display: flex;
}

.rodape__contato .telefoneFixo {
  align-items: center;
  gap: 24px;
  margin-top: 24px;
  display: flex;
}

@media screen and (width <= 767px) {
  .rodape__content {
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    justify-items: center;
    display: flex;
  }

  .rodape h2 {
    margin-top: 16px;
    font-size: 24px;
  }

  .rodape p {
    margin: 8px;
    font-size: 16px;
  }

  .rodape__contato .whatsapp, .rodape__contato .email, .rodape__contato .telefoneFixo {
    align-items: center;
    display: block;
  }
}

.bi {
  width: 10%;
  font-size: 24px;
}

@media screen and (width <= 767px) {
  .bi {
    font-size: 16px;
  }
}

.map-desktop {
  border-radius: 8px;
  margin-top: 16px;
}

@media screen and (width <= 767px) {
  .map-desktop {
    display: none;
  }
}

@media screen and (width >= 767px) {
  .map-mobile {
    border-radius: 8px;
    margin: 16px;
    display: none;
  }
}

h2 {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 500;
}

* {
  box-sizing: border-box;
  color: #fff;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  text-decoration: none;
  list-style: none;
}

.container {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

@media screen and (width <= 750px) {
  .container {
    max-width: 90%;
    overflow-x: hidden;
  }
}

.slick-dots li button:before {
  color: #fff;
}

.contactBtn {
  cursor: pointer;
  border: none;
  border-radius: 20px;
  width: 220px;
  margin-top: 40px;
  padding: 16px;
  transition: transform .2s ease-out, box-shadow .1s ease-in;
  box-shadow: 3px 3px 8px #676b99;
}

.contactBtn:hover {
  transform: translateY(-5px);
  box-shadow: 1px 3px 10px #fff;
}

.contactBtn a {
  color: #1d4650;
  font-size: 16px;
  font-weight: 600;
}

@media screen and (width <= 375px) {
  .contactBtn {
    width: 180px;
    margin-top: 16px;
    padding: 8px;
  }

  .contactBtn a {
    color: #1d4650;
    font-size: 12px;
    font-weight: 600;
  }
}
/*# sourceMappingURL=index.fe1efd56.css.map */
